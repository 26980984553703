<template>
    <div class="examdatadetail" v-html="examdata.content"></div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        examdata: {},
      };
    },
    methods: {
      renderdetail() {
        const id = this.$route.query.id
        const examinfolist = JSON.parse(sessionStorage.getItem("examdatalist"));
        for (const item of examinfolist) {
          if (item.id===id) {
            this.examdata=item
            return
          }
        }
      },
    },
    created() {
      this.renderdetail()
    },
  };
  </script>
  
  <style lang="less" scoped>
  .examdatadetail{
    padding: 5px;
  }
  </style>
  